import React, { useEffect } from 'react';
import FileUploader from './modules/components/FileUploader';
import { decodePDFRawStream, PDFArray, PDFDict, PDFDocument, PDFHexString, PDFName, PDFRawStream, PDFStream, PDFString } from 'pdf-lib';
//import jsonFile from './img/icons8-json-100.png';
import pdfFile from './img/icons8-pdf-100.png';
import FieldValueTable from './modules/components/FieldValueTable';
import logo from './img/cryptar-logo.svg';
import ValidationItem from './modules/components/ValidationItem';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ValidationState, FileInfo, DataJSON, ProofJSON } from './interfaces';
import { useState } from 'react';
import PdfPreview from './modules/components/PdfPreview';
import { sha256 } from 'crypto-hash';
import { sendRequestWithRetry } from './helpers';

const notAPIURL = 'https://notarization-objecthash.azurewebsites.net/api';
const cryptarServerURL = 'https://cryptarfunctions.azurewebsites.net';
const cryptarAPIDemoAppKey = 'rPdU0e456xAEuWcb8UWXh1rtUJEG-f6akzU-BdHIMrlbAzFuzvO1xw==';

const validatePDF = async (
  pdf: FileInfo,
  data: FileInfo,
  setPdfIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined' || typeof pdf.file === 'undefined')
      return;

    let dataContent = await data.file.text();
  
    if (typeof dataContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);

      if (typeof pdf.file === 'undefined') return;

      const hash = await sha256(await pdf.file.arrayBuffer());
      setPdfIsValid(
        hash === dataJSON.data.documentHash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateDataJSON = async (
  data: FileInfo,
  setDoubleCheckedDataJSON: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined') return;

    let dataContent = await data.file.text();

    if (typeof dataContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);

      const response = await sendRequestWithRetry(notAPIURL + '/rehash-object', {
        method: 'POST',
        body: JSON.stringify({
          data: dataJSON.data,
          salts: dataJSON.salts,
        }),
        headers: {
          'x-functions-key': cryptarAPIDemoAppKey,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }, 
      3, 500);

      if (!response.ok) {
        setValidationFailed(true);
      }

      const result = (await response.json()) as DataJSON;
      setDoubleCheckedDataJSON(
        result.hash === dataJSON.hash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateDataHash = async (
  data: FileInfo,
  proofFile: FileInfo,
  setProofKeyIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined' || typeof proofFile.file === 'undefined')
      return;

    let dataContent = await data.file.text();
    let proofContent = await proofFile.file.text();

    if (typeof dataContent === 'string' && typeof proofContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);
      const proofJSON: ProofJSON = JSON.parse(proofContent);
      setProofKeyIsValid(
        dataJSON.hash === proofJSON.dataHash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateProof = async (
  proofFile: FileInfo,
  setProofIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof proofFile.file === 'undefined') return;

    let proofContent = await proofFile.file.text();
    if (typeof proofContent === 'string') {
      const proofJSON: ProofJSON = JSON.parse(proofContent);

      const response = await sendRequestWithRetry(
        cryptarServerURL +
          '/api/SparseMerkleTree/' +
          proofJSON.smtId +
          '/' + proofJSON.dataHash,
        {
          method: 'GET',
          headers: {
            'x-functions-key': cryptarAPIDemoAppKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',            
          },
        }, 
        3, 500);

      if (!response.ok) {
        setValidationFailed(true);
      }

      const result = await response.json();
      setProofIsValid(
        result.hasOwnProperty('inclusionProofs')
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const runValidations = (certificateFile: FileInfo, dataFile: FileInfo, proofFile: FileInfo,
  setPdfIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setDoubleCheckedDataJSON: React.Dispatch<React.SetStateAction<ValidationState>>,
  setProofKeyIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setProofIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>,
  pdfIsValid: ValidationState, doubleCheckedDataJSON: ValidationState, proofKeyIsValid: ValidationState
) => {
  const PDFValidation = async () =>
    await validatePDF(certificateFile, dataFile, setPdfIsValid, setValidationFailed);
  const DataJsonValidation = async () =>
    await validateDataJSON(dataFile, setDoubleCheckedDataJSON, setValidationFailed);
  const DataHashValidation = async () =>
    await validateDataHash(dataFile, proofFile, setProofKeyIsValid, setValidationFailed);
  const ProofValidation = async () =>
    await validateProof(proofFile, setProofIsValid, setValidationFailed);

  PDFValidation().catch(() => setValidationFailed(true));
  DataJsonValidation().catch(() => setValidationFailed(true));
  DataHashValidation().catch(() => setValidationFailed(true));

  if (
    pdfIsValid === ValidationState.Valid &&
    doubleCheckedDataJSON === ValidationState.Valid &&
    proofKeyIsValid === ValidationState.Valid
  )
    ProofValidation().catch(() => setValidationFailed(true));
  else {
    setProofIsValid(ValidationState.Notchecked);
  }
}

const extractRawAttachments = (pdfDoc: PDFDocument) => {
  if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
  const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

  if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
  const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

  if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
  const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

  const rawAttachments = [];
  for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
    const fileName = EFNames.lookup(idx) as PDFHexString | PDFString;
    const fileSpec = EFNames.lookup(idx + 1, PDFDict);
    rawAttachments.push({ fileName, fileSpec });
  }

  return rawAttachments;
};

const EofMarker = "%%EOF";

const findLastEofIndex = (pdfData: Uint8Array): number => {
  return findLastOccurrence(pdfData, EofMarker);
};

const findSecondLastEofIndex = (
  pdfData: Uint8Array,
  lastEofIndex: number
): number => {
  return findLastOccurrence(pdfData, EofMarker, lastEofIndex - 1);
};

const findLastOccurrence = (
  data: Uint8Array,
  marker: string,
  startIndex: number = -1
): number => {
  const markerBytes = new TextEncoder().encode(marker);
  if (startIndex === -1) startIndex = data.length - markerBytes.length;

  for (let i = startIndex; i >= 0; i--) {
    let found = true;
    for (let j = 0; j < markerBytes.length; j++) {
      if (data[i + j] !== markerBytes[j]) {
        found = false;
        break;
      }
    }

    if (found) {
      return i;
    }
  }

  return -1;
};



function App() {

  const extractOriginalPdfFromCombined = async (file: File) => {
    if (!file) {
      console.log('No file provided!');
    }
    else{
      const pdfBytes = await file.arrayBuffer();
      const pdfData = new Uint8Array(pdfBytes);
      const eofIndex = findLastEofIndex(pdfData);
      const secondLastEofIndex = findSecondLastEofIndex(pdfData, eofIndex);
      let lengthOriginal = secondLastEofIndex + EofMarker.length;
      while (/\s/.test(String.fromCharCode(pdfData[lengthOriginal]))) {
        lengthOriginal++;
      }

      const originalPdf = new File([pdfData.slice(0, lengthOriginal)], file.name, {
        type: 'application/pdf',
      });
    
      setCertificatedFile({
        name: originalPdf.name,
        file: originalPdf,
      });
    }
  };

  const extractAttachments = async (file: File) => {
    // Load the PDF file
    if (!file) {
      console.log('No file provided!');
    }
    else{
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const rawAttachments = extractRawAttachments(pdfDoc);

      let dataFileExtracted: File | undefined;
      let proofFileExtracted: File | undefined;

      for (const { fileName, fileSpec } of rawAttachments) {
        const fileStream = fileSpec.lookup(PDFName.of('EF'), PDFDict).lookup(PDFName.of('F'), PDFStream) as PDFRawStream;
        
        const fileBytes = decodePDFRawStream(fileStream).decode();
        const blob = new Blob([fileBytes], { type: 'application/json' });
        const file = new File([blob], fileName.decodeText() as string, { type: 'application/json' });

        if (fileName.decodeText() === 'data.json') {
          dataFileExtracted = file;
        } else if (fileName.decodeText() === 'proof.json') {
          proofFileExtracted = file;
        } 
      }

      if (dataFileExtracted) {
        setDataFileFile({
          name: dataFileExtracted.name,
          file: dataFileExtracted,
        });
      }

      if (proofFileExtracted) {
        setProofFileFile({
          name: proofFileExtracted.name,
          file: proofFileExtracted,
        });
      }
    }  
  };

  const defaultFileInto: FileInfo = {
    name: 'drag & drop file',
  };
  const [combinedPdfFile, setCombinedPdfFile] = useState(defaultFileInto);
  const [certificateFile, setCertificatedFile] = useState(defaultFileInto);
  const [dataFile, setDataFileFile] = useState(defaultFileInto);
  const [proofFile, setProofFileFile] = useState(defaultFileInto);

  const [pdfIsValid, setPdfIsValid] = useState(ValidationState.Notchecked);
  const [doubleCheckedDataJSON, setDoubleCheckedDataJSON] = useState(
    ValidationState.Notchecked
  );
  const [proofKeyIsValid, setProofKeyIsValid] = useState(
    ValidationState.Notchecked
  );
  const [proofIsValid, setProofIsValid] = useState(ValidationState.Notchecked);

  const [validationFailed, setValidationFailed] = useState(false);

  const [certificateValidText, setCertificateValidText] = useState('Certificate is not checked');

  const [colorText, setColor] = useState('#f8f8f8');

  const setBoxText = (text: string) => {
    setCertificateValidText(text);
  };

  const removeChecks = () => {
    setPdfIsValid(ValidationState.Notchecked);
    setDoubleCheckedDataJSON(ValidationState.Notchecked);
    setProofKeyIsValid(ValidationState.Notchecked);
    setProofIsValid(ValidationState.Notchecked);
  };

  useEffect(() => {
    if (combinedPdfFile.file) {
      removeChecks();
      setColor('#f8f8f8');
      extractAttachments(combinedPdfFile.file as File);
      extractOriginalPdfFromCombined(combinedPdfFile.file as File);
    }
  }, [combinedPdfFile]);
  
  useEffect(() => {
    if (certificateFile.file && dataFile.file && proofFile.file) {
      runValidations(
        certificateFile,
        dataFile,
        proofFile,
        setPdfIsValid,
        setDoubleCheckedDataJSON,
        setProofKeyIsValid,
        setProofIsValid,
        setValidationFailed,
        pdfIsValid,
        doubleCheckedDataJSON,
        proofKeyIsValid
      );
    }
    if(pdfIsValid === ValidationState.Valid && doubleCheckedDataJSON === ValidationState.Valid && proofKeyIsValid === ValidationState.Valid && proofIsValid === ValidationState.Valid){
      setBoxText('Certificate is valid');
      setColor('#C6EFCE');
    }
    else if(pdfIsValid === ValidationState.Invalid || doubleCheckedDataJSON === ValidationState.Invalid || proofKeyIsValid === ValidationState.Invalid || proofIsValid === ValidationState.Invalid){
      setBoxText('Certificate is invalid');
      setColor('#FFC7CE');
    } else {
      setBoxText('Certificate is not checked');
      setColor('#f8f8f8');
    }
  }, [
    certificateFile,
    dataFile,
    proofFile,
    pdfIsValid,
    proofIsValid,
    doubleCheckedDataJSON,
    proofKeyIsValid,
  ]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={12} lg={7} container>
          <Grid item xs={12} sx={{ ml: 3, mt: 2 }}>
            <Grid item>
              <img src={logo} alt="Cryptar logo" />
            </Grid>
          </Grid>
          <Grid item xs={12} container direction="column" sx={{ ml: 2, mr: 2 }}>
            <Grid item container spacing={2} sx={{ mt: 2, mb: 6 }}>
              <Grid item sm={16} xs={12}>
                <FileUploader
                  headerText="COMBINED PDF"
                  fileIcon={pdfFile}
                  fileType="application/pdf"
                  fileInfo={combinedPdfFile}
                  setFile={setCombinedPdfFile}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ xs: { mt: 3 } }}>
              <FieldValueTable fileInfo={dataFile} />
            </Grid>
            {validationFailed ?
            <Grid item container direction="row" sx={{ mt: 2 }}>
              <Typography sx={{marginTop: '5px'}}>A validation error occured, please retry!</Typography>
              <Button
                onClick={() => {
                  runValidations(certificateFile, dataFile, proofFile, setPdfIsValid, setDoubleCheckedDataJSON, setProofKeyIsValid, 
                    setProofIsValid, setValidationFailed, pdfIsValid, doubleCheckedDataJSON, proofKeyIsValid);
                  setValidationFailed(false);
                }}
                sx={{
                  background: '#781e69',
                  color: 'white',
                  marginLeft: '20px',
                  border: 1,
                  borderColor: '#781e69',
                  borderRadius: 20,
                  pl: 3,
                  pr: 3,
                  mr: 2,
                  '&:hover': {
                    backgroundColor: '#fff',
                    color: '#781e69',
                    boxShadow: 5,
                  },
                }}
              >
                <Typography variant="button">Retry</Typography>
              </Button>
            </Grid> : <></>}
            <Grid item spacing={2} container direction="row" sx={{ mt: 2 }}>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="PDF matches DATA.JSON"
                  validationState={pdfIsValid}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="DATA JSON instact"
                  validationState={doubleCheckedDataJSON}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="Proof file matches DATA"
                  validationState={proofKeyIsValid}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="Proof key valid"
                  validationState={proofIsValid}
                />
              </Grid>
              <Grid item xs={12} md={12} >
                <Box sx={{ height: 50, mt: 2, fontFamily: 'Arial', border: 1, backgroundColor: colorText, borderColor: '#BDBDBD', borderRadius: 4, padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  {certificateValidText}
                </Box>
              </Grid>
            </Grid>
           
          </Grid>
        </Grid>
        <Grid item md={12} lg={5}>
          <Grid item xs={12} sx={{ ml: 3, mt: 2 }}>
            <PdfPreview fileInfo={certificateFile} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default App;
